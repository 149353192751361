<template>
    <v-container>
        <div style="margin: 95px 0;"></div>
        <Landing />
    </v-container>
</template>

<script>
import PopGallery from '@/components/pop/Index'
import Landing from '../../../components/pop/Landing'

export default {
    name: "PopGalleryLand",
    components: {
        PopGallery,
        Landing
    }
}
</script>