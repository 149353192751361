import AuthenticationController from "@/services/controllers/Authentication";
import axios from 'axios'
import store from '@/store'

const secretPhrase = async (data) => {
    let response = {};

    try {
        // Post to API to obtain the bearer token.
        response = await AuthenticationController.loginUserPopGallery(data)

        // Store the bearer token in localStorage.
        localStorage.setItem("accessToken", response.data.token);

        // Set the bearer token in Axios headers.
        axios.defaults.headers.common = {
            Authorization: `bearer ${response.data.token}`
        };

        // Store locally
        localStorage.setItem("refreshToken", response.data.refreshToken);
        localStorage.setItem("username", response.data.email);

        // Store on Vuex
        store.dispatch('User/signIn', response.data)

    }
    catch(err){
        response = { ...err.response }
    }

    return response;
}

export default secretPhrase;