<template>
      <div class="cityscape-container">
        <svg class="cityscape" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2709.33 189.68">
          <path  ref="path" fill='none' stroke='white' stroke-width='3' stroke-miterlimit='10' d="M-0.25,186.5l1014.87-0.09V77.28h128.61v47.62h-116.07v61.51h146.97v-31.04h-14.51
            v-99.1h195.12v98.72h-17.55v31.43h40.3v-54.18l-2.31,2.31l-1.16-1.16c0,0,16.39-17.55,44.73-8.68l-0.96,2.31l-7.33-1.54v10.8h-1.54
            v5.08h1.54v45.05h25.07v-56.49h33.74v56.49h22.75v-49.17h2.02V133h-2.02v-6.36c0,0,15.42-6.56,27.19,0V133h-2.1v4.24h2.1v49.17
            h28.73v-12.53l-11.18-41.07V56.27h46.08v76.93l-10.41,40.49v12.73H1600V5.37l57.07,24.87v156.18h1052.01"/>

          <rect v-show="cityscapeVisible" id="screen1" x="1021.92" y="84.56" class="st0" width="113.18" height="32.67"/>
          <rect v-show="cityscapeVisible" id="screen2" x="1166.81" y="63.27" class="st0" width="180.54" height="84.56"/>
          <rect v-show="cityscapeVisible" id="screen3" x="1383.48" y="133.27" class="st0" width="19.96" height="32.29"/>
          <rect v-show="cityscapeVisible" id="screen4" x="1440.14" y="135.6" class="st0" width="24.24" height="32.29"/>
          <rect v-show="cityscapeVisible" id="screen5" x="1496.23" y="141.27" class="st0" width="17.98" height="32.29"/>
          <rect v-show="cityscapeVisible" id="screen6" x="1543.23" y="63.27" class="st0" width="32.5" height="68.15"/>
          <rect v-show="cityscapeVisible" id="screen7" x="1605.23" y="39.95" class="st0" width="45.43" height="91.47"/>
        </svg>
      </div>
      <!--
      <svg class="line-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 3800 1">
        <line x1="0" y1="1" x2="3800" y2="1" :stroke="lineColor" stroke-width="30" stroke-miterlimit="" />
      </svg>-->
  </template>
  
<script>
import gsap from 'gsap'
import { Power1 } from 'gsap';

  export default {
    data() {
      return {
        lineColor: 'white',
        cityscapeVisible: false
      };
    },
    mounted() {
        // USe gsapto animate the line
        gsap.from(this.$el.querySelector('.line-svg'), {
          duration: 1,
          x: '-100%',
          ease: 'power2.inOut',
        });
        const path = this.$refs.path;
        const length = path.getTotalLength();

        // Set the initial state of the path
        gsap.set(path, { strokeDasharray: length, strokeDashoffset: length });

        // Animate the path from left to right
        gsap.to(path, { 
          duration: 6, 
          strokeDashoffset: 0, 
          ease: Power1.easeInOut,
          onComplete: () => {
              this.cityscapeVisible = true
              // Set the flag to true to show the cityscape
              gsap.fromTo(
                this.$el.querySelectorAll('rect'),
                { opacity: 0, y: 100 },
                {
                  duration: 0.5,
                  opacity: 1,
                  y: 0,
                  stagger: 0.1,
                  ease: 'power2.inOut',
                }
              );
            },
         });
        // animate all rect elements to apear from bottom to top after the path is animated
      },
    methods: {
    }
  };
  </script>
  
  <style scoped>
  
  
  .cityscape-container {
    width:100%; 
    height: fit-content;
    padding: 0;
    margin: 0;
    overflow: hidden;
    align-items: center;
    z-index: 1;
    box-shadow: 0px 0px 30px 0px rgba(0,0,0,1);
  }
  
  .cityscape {
    margin-top: 10px;
    padding-bottom: 10px;
    background-color: #121212;
    z-index: 1;
    
  }
  .st0{fill:#00A7C1;}
</style>
  